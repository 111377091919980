import '@common/css/global.scss';

import { useEffect, useRef, useState } from 'react';
import { AppProps } from 'next/app';
import Script from 'next/script';
import { ReCaptchaProvider } from 'next-recaptcha-v3';
import { ErrorBoundary } from '@sentry/nextjs';
import { createBrowserSupabaseClient } from '@supabase/auth-helpers-nextjs';
import { SessionContextProvider } from '@supabase/auth-helpers-react';
import A11yDialogInstance from 'a11y-dialog';
import classNames from 'classnames';

import { ArrowDecoration } from '@components/arrowDecoration/ArrowDecoration';
import { DraftBanner } from '@components/draftBanner/DraftBanner';
import { Footer } from '@components/footer/Footer';
import { MetaPixel } from '@components/metaPixel/metaPixel';
import { Navigation } from '@components/navigation/Navigation';
import { NavigationFullscreen } from '@components/navigation/NavigationFullscreen';
import { TextMarquee } from '@components/textMarquee/TextMarquee';
import { TrackingCode } from '@components/trackingCode/TrackingCode';
import { UnsubscribedWarningBanner } from '@components/unsubscribedWarningBanner/UnsubscribedWarningBanner';
import { VideoBanner } from '@components/videoBanner/VideoBanner';
import { VwoCode } from '@components/vwoCode/VwoCode';

import { getBodyCustomProperties, getBodyId } from '@common/utils/body';
import { getDatoPage } from '@common/utils/dato';
import { isProduction } from '@common/utils/environment';
import { handleError } from '@common/utils/handleError';
import { UTM } from '@common/utils/tracking';
import { CartContextProvider } from '@context/CartContext';
import { UserContextProvider } from '@context/UserContext';
import { MEMBERS_URL, SHOP_COLLECTIONS_URL, SHOP_URL } from '@data/constants';
import { useAxe } from '@hooks/useAxe';
import { useKeyboardFocus } from '@hooks/useKeyboardFocus';
import { useLazyloadMedia } from '@hooks/useLazyloadMedia';
import { useVideoVisibility } from '@hooks/useVideoVisibility';
import { NavigationRecord, SiteFooter } from '@interfaces';

import ErrorPage from './_error';

interface CustomPageProps {
	footer?: SiteFooter;
	isArrowDecorationVisible?: boolean;
	navigation?: NavigationRecord;
	statusCode?: number;
	isDraftMode?: boolean;
	isLightNav?: boolean;
	isFullBleedHeader?: boolean;
}

const CustomApp = ({
	Component,
	pageProps,
	router,
}: AppProps<CustomPageProps>) => {
	const [supabaseClient] = useState(() => createBrowserSupabaseClient());
	const [navigationFullscreenInstance, setNavigationFullscreenInstance] =
		useState<A11yDialogInstance>();

	const layoutRef = useRef<HTMLDivElement>(null);

	const datoPage = getDatoPage(pageProps);
	const firstModule = datoPage?.modules?.length ? datoPage?.modules[0] : null;
	const moduleTypeName = '__typename';
	const isFirstModuleCover =
		!!firstModule && firstModule[moduleTypeName] === 'CoverSectionRecord';
	const isLightText =
		!!(isFirstModuleCover && firstModule?.textColor === 'Light') ||
		pageProps?.isLightNav;
	const isFullBleedCover =
		!!(isFirstModuleCover && firstModule?.isFullBleedMedia) ||
		pageProps?.isFullBleedHeader;

	const isHomepage = router.pathname === '/';

	useLazyloadMedia();
	useVideoVisibility();
	useKeyboardFocus();
	useAxe();

	useEffect(() => {
		const bodyCustomProperties = getBodyCustomProperties(pageProps);
		const id = getBodyId(router.pathname, router.query);

		if (id) {
			document.body.setAttribute('id', id);
		} else {
			document.body.removeAttribute('id');
		}

		if (bodyCustomProperties) {
			Object.entries(bodyCustomProperties).forEach(([property, value]) => {
				document.body.style.setProperty(property, value);
			});
		} else {
			document.body.removeAttribute('style');
		}

		try {
			const urlParams = new URLSearchParams(window.location.search);
			const utmSource = urlParams.get(UTM.Source);
			const utmMedium = urlParams.get(UTM.Medium);
			const utmCampaign = urlParams.get(UTM.Campaign);

			if (utmSource || utmMedium || utmCampaign) {
				sessionStorage.removeItem(UTM.Source);
				sessionStorage.removeItem(UTM.Medium);
				sessionStorage.removeItem(UTM.Campaign);
			}

			if (utmSource) {
				sessionStorage.setItem(UTM.Source, utmSource);
			}
			if (utmMedium) {
				sessionStorage.setItem(UTM.Medium, utmMedium);
			}
			if (utmCampaign) {
				sessionStorage.setItem(UTM.Campaign, utmCampaign);
			}
		} catch (error) {
			handleError(error);
		}
	});

	if (pageProps.statusCode) {
		return <ErrorPage statusCode={pageProps.statusCode} />;
	}

	if (
		(!pageProps.footer || !pageProps.navigation) &&
		router.pathname !== '/_error' &&
		!router.asPath.startsWith(MEMBERS_URL)
	) {
		throw new Error('Make sure site data is fetched for this page');
	}

	const isShopMarqueeVisible =
		router.asPath === SHOP_URL ||
		router.asPath.startsWith(SHOP_COLLECTIONS_URL);

	return (
		<ErrorBoundary fallback={<p>An error has occurred.</p>}>
			<ReCaptchaProvider
				reCaptchaKey={process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY}
			>
				<SessionContextProvider supabaseClient={supabaseClient}>
					<UserContextProvider>
						<CartContextProvider>
							<div
								className={classNames('layout', {
									'layout--is-home': isHomepage,
								})}
								ref={layoutRef}
							>
								{pageProps?.isDraftMode ? <DraftBanner /> : null}
								{datoPage?.videoBanner ? (
									<VideoBanner
										className="layout__video-banner"
										videoBanner={datoPage.videoBanner}
									/>
								) : null}
								{pageProps.isArrowDecorationVisible ? (
									<ArrowDecoration />
								) : null}
								{pageProps.navigation ? (
									<>
										<div
											className={classNames('layout__navigation', {
												'layout__navigation--home': isHomepage,
												'layout__navigation--is-light-text': isLightText,
												'layout__navigation--has-cover': isFirstModuleCover,
												'layout__navigation--is-full-bleed-cover':
													isFullBleedCover,
											})}
										>
											<Navigation
												navigationFullscreenInstance={
													navigationFullscreenInstance
												}
											/>
										</div>
										<div
											className={classNames('layout__sticky-navigation', {
												'layout__sticky-navigation--home': isHomepage,
												'layout__sticky-navigation--is-light-text': isLightText,
												'layout__sticky-navigation--is-full-bleed-cover':
													isFullBleedCover,
											})}
										>
											<NavigationFullscreen
												navigation={pageProps.navigation}
												navigationFullscreenInstance={
													navigationFullscreenInstance
												}
												overrideMobileOpenButtonColor="sand"
												setNavigationFullscreenInstance={
													setNavigationFullscreenInstance
												}
											/>
										</div>
									</>
								) : null}
								<main className="layout__content">
									{isShopMarqueeVisible && datoPage?.title ? (
										<TextMarquee
											className="layout__marquee"
											text={datoPage.title}
										/>
									) : null}
									<Component {...pageProps} />
								</main>
								{pageProps.footer ? <Footer footer={pageProps.footer} /> : null}
							</div>
							<UnsubscribedWarningBanner />
							{isProduction() ? <TrackingCode /> : null}
							{process.env.NEXT_PUBLIC_META_PIXEL_ID ? (
								<MetaPixel pixelId={process.env.NEXT_PUBLIC_META_PIXEL_ID} />
							) : null}
							{process.env.NEXT_PUBLIC_VWO_ACCOUNT_ID ? (
								<VwoCode accountId={process.env.NEXT_PUBLIC_VWO_ACCOUNT_ID} />
							) : null}
							<Script id="scroll-to-hash-element" strategy="lazyOnload">
								{`
								function scrollToHashElement() {
									const hash = window.location.hash;

									if (!hash.length) return;

									if (hash.includes('access_token')) return;

									const hashElement = document.querySelector(hash);

									if (!hashElement) return;

									hashElement.scrollIntoView();
								}

								scrollToHashElement();
							`}
							</Script>
						</CartContextProvider>
					</UserContextProvider>
				</SessionContextProvider>
			</ReCaptchaProvider>
		</ErrorBoundary>
	);
};

export default CustomApp;
