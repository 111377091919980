// Function in this file take `supabaseClient` as a parameter and can therefore be used client and server side
import {
	PostgrestResponse,
	PostgrestSingleResponse,
	SupabaseClient,
} from '@supabase/supabase-js';

import { getErrorMessage } from '@common/utils/errorMessage';
import { handleError } from '@common/utils/handleError';
import { EVENT_INDEX_PAGE_SIZE } from '@data/constants';
import { mapEventData } from '@data/dice/mappers';
import {
	DiceEvent,
	DiceEventResponse,
	DiceQueryArguments,
	SupabaseInviteRecord,
	SupabaseProductRecordWithPrices,
	SupabaseReservationRecord,
	SupabaseReservationRecordWithEvent,
	SupabaseSubscriptionRecordWithPrices,
} from '@interfaces';

interface GetEventDataParams {
	supabaseClient: SupabaseClient;
	supabaseFunction: string;
	queryArguments: DiceQueryArguments;
	pageNumber: number;
}

export async function getEventData({
	supabaseClient,
	supabaseFunction,
	queryArguments,
	pageNumber,
}: GetEventDataParams) {
	// Determine pagination range
	const rangeFrom = (pageNumber - 1) * EVENT_INDEX_PAGE_SIZE;
	const rangeTo = pageNumber * EVENT_INDEX_PAGE_SIZE - 1;

	const eventResponse: PostgrestResponse<{ data: DiceEventResponse }> =
		await supabaseClient
			.rpc(supabaseFunction, queryArguments, { count: 'estimated' })
			.select('data')
			.range(rangeFrom, rangeTo);

	if (eventResponse.error) {
		handleError(eventResponse.error.message);
	}

	if (eventResponse.data && eventResponse.count) {
		const hasNextPage =
			eventResponse.count > pageNumber * EVENT_INDEX_PAGE_SIZE;

		const events = eventResponse.data.map(({ data: eventData }) => {
			return mapEventData(eventData);
		});

		return {
			events,
			pageNumber,
			hasNextPage,
		};
	}

	return {
		events: [],
		pageNumber,
		hasNextPage: false,
	};
}

interface GetAvailableTicketCountParams {
	supabaseClient: SupabaseClient;
	event: DiceEvent;
}

// Returns the available ticket count by substraction the total reserved tickets from the reservation cap that is venue based.
export const getAvailableTicketCount = async ({
	supabaseClient,
	event,
}: GetAvailableTicketCountParams): Promise<number | undefined> => {
	try {
		const {
			error,
			data: availableTicketCount,
		}: PostgrestSingleResponse<number> = await supabaseClient
			.rpc('get_available_ticket_count_v2', {
				event_id: event.intId,
			})
			.single();

		if (error) {
			throw error;
		}

		return availableTicketCount;
	} catch (error) {
		handleError(getErrorMessage(error));
	}
};

interface GetReservationRecordParams {
	supabaseClient: SupabaseClient;
	event: DiceEvent;
}

// Returns reservation record for authenticated user for provided event
export const getActiveReservationRecord = async ({
	supabaseClient,
	event,
}: GetReservationRecordParams) => {
	try {
		const { error, data }: PostgrestResponse<SupabaseReservationRecord> =
			await supabaseClient
				.from('reservations')
				.select()
				.eq('event_id', event.intId)
				.eq('status', 'active');

		if (error) {
			throw error;
		}

		const [firstRecord] = data;

		return firstRecord;
	} catch (error) {
		handleError(getErrorMessage(error));
	}
};

interface GetUpcomingReservationCountParams {
	supabaseClient: SupabaseClient;
}

export const getUpcomingReservationCount = async ({
	supabaseClient,
}: GetUpcomingReservationCountParams) => {
	try {
		const { error, data }: PostgrestSingleResponse<number> =
			await supabaseClient.rpc('get_active_reservation_count_v2').single();

		if (error) {
			handleError(error.message);
		}

		return data;
	} catch (error) {
		handleError(getErrorMessage(error));
	}
};

interface GetInviteRecordParams {
	supabaseClient: SupabaseClient;
	inviteId: string;
	isRedeemed: boolean;
}

export const getInviteRecord = async ({
	supabaseClient,
	inviteId,
	isRedeemed,
}: GetInviteRecordParams) => {
	try {
		const { error, data } = await supabaseClient
			.from('invites')
			.select<'*', SupabaseInviteRecord>('*')
			.eq('id', inviteId)
			.eq('is_redeemed', isRedeemed)
			.maybeSingle();

		if (error) {
			throw error;
		}

		return data;
	} catch (error) {
		handleError(getErrorMessage(error));

		return null;
	}
};

interface GetSubscriptionParams {
	supabaseClient: SupabaseClient;
}

export const getSubscription = async ({
	supabaseClient,
}: GetSubscriptionParams) => {
	try {
		const { error, data } = await supabaseClient
			.from('subscriptions')
			.select<
				'*, prices(*, products(*))',
				SupabaseSubscriptionRecordWithPrices
			>('*, prices(*, products(*))')
			.in('status', ['trialing', 'active', 'past_due'])
			.order('created', { ascending: true })
			.limit(1);

		if (error) {
			handleError(error.message);
		}

		if (data?.length) {
			return data[0];
		}

		return null;
	} catch (error) {
		handleError(getErrorMessage(error));

		return null;
	}
};

interface GetReservationsParams {
	supabaseClient: SupabaseClient;
}

export const getReservations = async ({
	supabaseClient,
}: GetReservationsParams) => {
	try {
		const { error, data } = await supabaseClient
			.from('active_reservation_view')
			.select<'*', SupabaseReservationRecordWithEvent>('*');

		if (error) {
			handleError(error.message);
		}

		return data;
	} catch (error) {
		handleError(getErrorMessage(error));

		return null;
	}
};

interface GetProductForTierLevelParams {
	supabaseClient: SupabaseClient;
	tierLevel: '1' | '2' | '3';
}

export const getProductForTierLevel = async ({
	supabaseClient,
	tierLevel,
}: GetProductForTierLevelParams) => {
	const { data, error } = await supabaseClient
		.from('products')
		.select<'*, prices(*)', SupabaseProductRecordWithPrices>('*, prices(*)')
		.eq('active', true)
		.eq('prices.active', true)
		.eq('metadata->>tier_level', tierLevel)
		.maybeSingle();

	if (error) {
		handleError(error.message);
	}

	if (data) {
		return data;
	}

	return null;
};
