/* eslint-disable camelcase */
import { MEMBERSHIP_URL } from '@data/constants';
import {
	SupabasePriceRecord,
	SupabasePriceRecordWithProducts,
	SupabasePriceRecordWithSubscriptions,
	SupabaseProductRecord,
	SupabaseProductRecordWithPricesAndSubscriptions,
	SupabaseSubscriptionRecordWithPrices,
	SupabaseSubscriptionRecordWithPricesAndGifts,
} from '@interfaces';

export const getSoldSubscriptionCount = (
	prices: SupabasePriceRecordWithSubscriptions[] = [],
) => {
	return prices.reduce((accumulator, { subscriptions }) => {
		if (subscriptions) {
			// Note that we’re not filtering for active or trialing subscriptions
			// as we’re already filtering on those when querying the database.
			return accumulator + subscriptions.length;
		}

		return accumulator;
	}, 0);
};

export const getIsMembershipTierAvailable = (
	product: SupabaseProductRecordWithPricesAndSubscriptions,
) => {
	const {
		metadata: { allocation, is_available },
		prices,
	} = product;

	if (is_available === 'yes') {
		return getSoldSubscriptionCount(prices) < Number(allocation);
	}

	return false;
};

interface GetIsMembershipTierGiftAvailableProps {
	product: SupabaseProductRecordWithPricesAndSubscriptions;
	unredeemedMembershipGiftCount: number;
}

export const getIsMembershipTierGiftAvailable = ({
	product,
	unredeemedMembershipGiftCount,
}: GetIsMembershipTierGiftAvailableProps) => {
	const {
		metadata: { allocation, gift_allocation },
		prices,
	} = product;

	return (
		getSoldSubscriptionCount(prices) + unredeemedMembershipGiftCount <
		Number(allocation) + Number(gift_allocation)
	);
};

export const getSubscriptionMetadata = (
	subscription?: SupabaseSubscriptionRecordWithPrices | null,
) => {
	return subscription?.prices?.products?.metadata || {};
};

export const getIsMembershipTierRelease = () => {
	const releaseDate = process.env.NEXT_PUBLIC_MEMBERSHIP_TIER_RELEASE_DATE;

	return typeof releaseDate === 'string'
		? new Date().toISOString() > releaseDate
		: false;
};

export const getSubscriptionName = (
	subscription: SupabaseSubscriptionRecordWithPrices,
) => {
	return subscription.prices?.products?.name;
};

export const getLatestSubscription = (
	subscriptions: SupabaseSubscriptionRecordWithPricesAndGifts[],
): SupabaseSubscriptionRecordWithPricesAndGifts | undefined => {
	return subscriptions.length > 1
		? subscriptions.sort((a, b) => -a.created.localeCompare(b.created))[0]
		: subscriptions[0];
};

export const getThreeMonthIntervalPrice = (
	prices:
		| SupabasePriceRecord[]
		| SupabasePriceRecordWithProducts[]
		| SupabasePriceRecordWithSubscriptions[]
		| undefined,
) => {
	if (!prices) {
		return;
	}

	// Return the highest price for a 3-month billing interval
	const threeMonthIntervalPrices = prices
		.filter(({ interval, interval_count }) => {
			return interval === 'month' && interval_count === 3;
		})
		.sort((a, b) => {
			const amountA = a.unit_amount || 0;
			const amountB = b.unit_amount || 0;

			return amountB - amountA;
		});

	if (!threeMonthIntervalPrices.length) {
		return;
	}

	return threeMonthIntervalPrices[0];
};

export const getAnnualIntervalPrice = (
	prices:
		| SupabasePriceRecord[]
		| SupabasePriceRecordWithProducts[]
		| SupabasePriceRecordWithSubscriptions[]
		| undefined,
) => {
	if (!prices) {
		return;
	}

	// Return the highest price for a annual billing interval
	const annualIntervalPrices = prices
		.filter(({ interval, interval_count }) => {
			return interval === 'year' && interval_count === 1;
		})
		.sort((a, b) => {
			const amountA = a.unit_amount || 0;
			const amountB = b.unit_amount || 0;

			return amountB - amountA;
		});

	if (!annualIntervalPrices.length) {
		return;
	}

	return annualIntervalPrices[0];
};

export const getBillingLengthInMonths = (start: string, end: string) => {
	try {
		const startDate = new Date(start);
		const endDate = new Date(end);

		const differenceInMilliseconds = Math.abs(
			endDate.getTime() - startDate.getTime(),
		);

		// eslint-disable-next-line extra-rules/no-commented-out-code
		// milliseconds * seconds * minutes * hours * days
		const millisecondsInThirtyDays = 1000 * 60 * 60 * 24 * 30;

		return Math.ceil(differenceInMilliseconds / millisecondsInThirtyDays);
	} catch {
		return 'Invalid range';
	}
};

export const getCheckoutUrl = <Type extends SupabaseProductRecord>({
	product,
	isAnnualSub,
	isGift = false,
}: {
	product: Type;
	isAnnualSub: boolean;
	isGift?: boolean;
}) => {
	// Use example.com as a base URL
	const base = 'https://www.example.com';

	const url = isGift
		? `${MEMBERSHIP_URL}/${product.slug}/gift/checkout`
		: `${MEMBERSHIP_URL}/${product.slug}/checkout`;

	const urlObject = new URL(url, base);

	if (isAnnualSub) {
		urlObject.searchParams.set('sub-interval', 'annual');
	}

	// Only return the pathname and search, stripping the example.com base URL
	return urlObject.pathname + urlObject.search;
};
